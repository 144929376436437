import { computed, ref } from "vue";
import { useStorage } from "@vueuse/core";

import router from "@/router";
import { baseUrl } from "@/config";

export type UserCredentials = {
  email: string;
  password: string;
};

const jwt = useStorage("jwt", null);
const refreshTokenInProgress = ref(false); // Using ref for global reactive variable
const subscribers = ref([]); // Using ref for global reactive array

export const useAuth = () => {
  const isLoading = ref(false);

  const isAuthenticated = computed(() => jwt.value !== null);

  const login = async (credentials: UserCredentials) => {
    isLoading.value = true;

    const response = await fetch(`${baseUrl}/userlogin`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    });

    const responseBody = await response.json();
    const { status } = response;
    const { message } = responseBody;

    isLoading.value = false;

    if (status === 200) {
      jwt.value = responseBody.jwt;
      localStorage.setItem("uuid", responseBody.uuid);
      localStorage.setItem("refreshToken", responseBody.refreshToken);
      router.push({ name: "projects" });
    } else if (status === 409) {
      localStorage.setItem("resetToken", responseBody.resetToken);
      localStorage.setItem("userUUID", responseBody.userUUID);
    }

    return { message, status };
  };

  const forceLogin = async () => {
    isLoading.value = true;

    const body = {
      userUUID: localStorage.getItem("userUUID"),
      resetToken: localStorage.getItem("resetToken"),
    };

    const response = await fetch(`${baseUrl}/resetlicence`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const responseBody = await response.json();
    const { message } = responseBody;
    const { status } = response;

    isLoading.value = false;

    if (status === 200) {
      jwt.value = responseBody.jwt;
      localStorage.setItem("uuid", responseBody.uuid);
      localStorage.setItem("refreshToken", responseBody.refreshToken);
    }

    return { message, status };
  };

  const cancelForceLogin = () => {
    localStorage.removeItem("resetToken");
    localStorage.removeItem("userUUID");
  };

  const logout = () => {
    fetch(`${baseUrl}/userlogout`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorizationToken: `Bearer ${jwt.value}`,
      },
    });
    localStorage.removeItem("jwt");
    localStorage.removeItem("uuid");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("role");
    router.push({ name: "login" });
  };

  const refreshToken = async () => {
    if (!refreshTokenInProgress.value) {
      refreshTokenInProgress.value = true;

      const response = await fetch(`${baseUrl}/refreshtoken`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorizationToken: `Bearer ${jwt.value}`,
        },
        body: JSON.stringify({
          userUUID: localStorage.getItem("uuid"),
          refreshToken: localStorage.getItem("refreshToken"),
        }),
      });

      const responseBody = await response.json();
      const { status } = response;

      if (status === 200) {
        jwt.value = responseBody.jwt;
        localStorage.setItem("uuid", responseBody.uuid);
        localStorage.setItem("refreshToken", responseBody.refreshToken);
      } else {
        localStorage.removeItem("jwt");
        localStorage.removeItem("uuid");
        localStorage.removeItem("refreshToken");
        router.push({ name: "login" });
      }

      refreshTokenInProgress.value = false;

      while (subscribers.value.length > 0) {
        const subscriber = subscribers.value.pop();
        // @ts-ignore
        subscriber(status);
      }
      return { status };
    } else {
      return new Promise((resolve) => {
        // @ts-ignore
        subscribers.value.push(resolve);
      });
    }
  };

  return {
    isAuthenticated,
    isLoading,
    login,
    forceLogin,
    cancelForceLogin,
    logout,
    refreshToken,
  };
};
