export const flatten = <Type>(
  data: Type,
  keys: Extract<keyof Type, string>[]
) => {
  const flatData: any = {};
  keys.forEach((key) => {
    const prefix = key;
    const nestedData = data[key];
    for (const realKey in nestedData) {
      const flatKey =
        prefix + realKey.charAt(0).toUpperCase() + realKey.substring(1);
      flatData[flatKey] = nestedData[realKey];
    }
  });

  const generalData: any = {};
  for (const realKey in data) {
    if (!keys.includes(realKey)) {
      generalData[realKey] = data[realKey];
    }
  }

  return {
    ...generalData,
    ...flatData,
  };
};

export const unflatten = <Type>(
  flatData: any,
  keys: Extract<keyof Type, string>[]
) => {
  const data: any = {};
  keys.forEach((key) => {
    const prefix = key;
    const nestedData: any = {};
    for (const flatKey in flatData) {
      if (flatKey.includes(prefix)) {
        const realKey =
          flatKey.replace(prefix, "").charAt(0).toLowerCase() +
          flatKey.replace(prefix, "").substring(1);
        nestedData[realKey] = flatData[flatKey];
      }
    }
    data[key] = nestedData;
  });

  const generalData: any = {};
  for (const dataKey in flatData) {
    if (!keys.some((key) => dataKey.includes(key))) {
      generalData[dataKey] = flatData[dataKey];
    }
  }

  return {
    ...generalData,
    ...data,
  } as Type;
};
