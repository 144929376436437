import { rest } from "msw";
import { v4 as uuidv4 } from "uuid";

export const handlerGenerator = (entityName: string) => [
  rest.get(`/${entityName}`, (req, res, ctx) => {
    const filter = req.url.searchParams;
    const items = JSON.parse(window.localStorage.getItem(entityName) || "[]");
    const filteredItems = items.filter((el: any) => {
      let result = true;
      for (const [key, value] of filter) {
        result = el[key] === value && result;
      }
      return result;
    });
    return res(ctx.status(200), ctx.json(filteredItems));
  }),

  rest.get(`/${entityName}/:entityId`, (req, res, ctx) => {
    const item = JSON.parse(
      window.localStorage.getItem(entityName) || "[]"
    ).find((el: any) => el.id === req.params.entityId);
    return res(ctx.status(200), ctx.json(item));
  }),

  rest.post(`/${entityName}`, async (req, res, ctx) => {
    const item = await req.json();
    item.id = uuidv4();
    const items = JSON.parse(window.localStorage.getItem(entityName) || "[]");
    items.push(item);
    window.localStorage.setItem(entityName, JSON.stringify(items));
    return res(ctx.status(200), ctx.json(item));
  }),

  rest.delete(`/${entityName}/:entityId`, async (req, res, ctx) => {
    const items = JSON.parse(window.localStorage.getItem(entityName) || "[]");
    const targetIndex = items.findIndex(
      (el: any) => el.id === req.params.entityId
    );
    items.splice(targetIndex, 1);
    window.localStorage.setItem(entityName, JSON.stringify(items));
    return res(ctx.status(200));
  }),

  rest.put(`/${entityName}/:entityId`, async (req, res, ctx) => {
    const items = JSON.parse(window.localStorage.getItem(entityName) || "[]");
    const targetIndex = items.findIndex(
      (el: any) => el.id === req.params.entityId
    );
    items.splice(targetIndex, 1, await req.json());
    window.localStorage.setItem(entityName, JSON.stringify(items));
    return res(ctx.status(200));
  }),

  rest.patch(`/${entityName}/:entityId`, async (req, res, ctx) => {
    const items = JSON.parse(window.localStorage.getItem(entityName) || "[]");
    const targetIndex = items.findIndex(
      (el: any) => el.id === req.params.entityId
    );
    const updatedItem = { ...items[targetIndex], ...(await req.json()) };
    items.splice(targetIndex, 1, updatedItem);
    window.localStorage.setItem(entityName, JSON.stringify(items));
    return res(ctx.status(200));
  }),
];

export default [
  "projects",
  "economicEntities",
  "headers",
  "labels",
  "costItems",
  "compositeCostItems",
  "comments",
  "budgetClasses",
  "companies",
  "macroareas",
  "microareas",
  "paymentCodes",
  "paymentTypes",
  "projectStates",
  "sponsorBusinesses",
  "sponsorServices",
  "sponsorKits",
  "suppliers",
  "taxes",
  "estimateSponsors",
  "sponsorBudgets",
  "orders",
  "sponsorEstimates",
  "sponsorBudgets",
  "sponsorEstimateCostItems",
  "sponsorEstimateLabels",
  "sponsorEstimateComments",
  "sponsorEstimateHeaders",
  "sponsorEstimateCompositeCostItems",
  "sponsorBudgetCostItems",
  "estimateProspectusCostItems",
  "estimateProspectusLabels",
  "estimateProspectusComments",
  "estimateProspectusHeaders",
  "estimateProspectusCompositeCostItems",
  "budgetProspectusCostItems",
  "budgetProspectusLabels",
  "budgetProspectusComments",
  "budgetProspectusHeaders",
  "budgetProspectusCompositeCostItems",
  "classes",
  "spaces",
  "prices",
  "educationalGoals",
  "educationTypes",
  "providers",
  "agendaSessions",
  "jobs",
  "disciplines",
  "registrationCategories",
  "teamMembers",
  "companyTeams",
]
  .map(handlerGenerator)
  .flat();
