const ACTIVE_BRANCH = process.env.VUE_APP_VERCEL_GIT_COMMIT_REF ?? "dev";

const API_URLS: Record<string, string> = {
  dev: "https://2mcyskkfo3.execute-api.eu-west-1.amazonaws.com/api",
  uat: "https://g36jc2xrn2.execute-api.eu-west-1.amazonaws.com/api",
  prod: "https://lnafmb6bre.execute-api.eu-west-1.amazonaws.com/api",
};

export let apiEnv = "dev";

if (ACTIVE_BRANCH.includes("release")) {
  apiEnv = "uat";
} else if (ACTIVE_BRANCH === "prod") {
  apiEnv = "prod";
}

export const baseUrl = API_URLS[apiEnv];
