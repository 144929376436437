import { readonly, ref } from "vue";

type DialogState = {
  show: boolean;
  type: "alert" | "confirm";
  title?: string;
  message?: string;
  onConfirm: () => void;
  onCancel: () => void;
};

type AlertDialogOptions = {
  title?: string;
  message?: string;
  onConfirm?: () => void;
};

type ConfirmDialogOptions = {
  title?: string;
  message?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
};

const state = ref<DialogState>({
  show: false,
  type: "alert",
  title: undefined,
  message: undefined,
  onConfirm: () => {},
  onCancel: () => {},
});

export const useDialog = () => {
  const alert = (options?: AlertDialogOptions) => {
    state.value = {
      show: true,
      type: "alert",
      title: options?.title,
      message: options?.message,
      onConfirm: () => {
        state.value.show = false;
        options?.onConfirm?.();
      },
      onCancel: () => {},
    };
  };

  const confirm = (options?: ConfirmDialogOptions) => {
    state.value = {
      show: true,
      type: "confirm",
      title: options?.title,
      message: options?.message,
      onConfirm: () => {
        state.value.show = false;
        options?.onConfirm?.();
      },
      onCancel: () => {
        state.value.show = false;
        options?.onCancel?.();
      },
    };
  };

  return {
    state: readonly(state),
    alert,
    confirm,
  };
};
