import { VueQueryPlugin } from "@tanstack/vue-query";
import vClickOutside from "click-outside-vue3";
import { createApp } from "vue";
import { createI18n } from "vue-i18n";

import { itDateTime } from "@/dateTime/it";
import it from "@/lang/it.json";
import { worker } from "@/mocks/browser";

import App from "./App.vue";
import router from "./router";

const i18n = createI18n({
  locale: "it",
  messages: {
    it,
  },
  datetimeFormats: {
    ...itDateTime,
  },
});

worker.start({
  onUnhandledRequest: "bypass",
});

const app = createApp(App)
  .use(router)
  .use(i18n)
  .use(vClickOutside)
  .use(VueQueryPlugin);

app.config.warnHandler = (msg, vm, trace) => {
  if (
    msg.includes("Extraneous non-") &&
    (trace.includes("<GridCell>") || trace.includes("<ComplexGrid>"))
  ) {
    return;
  }

  console.warn(msg, trace);
};

app.mount("#app");
