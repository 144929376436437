import { Operators } from "@progress/kendo-vue-data-tools";
import { computed, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { MenuItemModel } from "@progress/kendo-vue-layout";

import { Field } from "@/ui/useGrid";

import { useMySWRV } from "./useMySWRV";
// eslint-disable-next-line
import {
  Comment,
  CompositeCostItem,
  CostItem,
  Header,
  Label,
} from "./useEconomicEntityItems";

export type EconomicEntity = {
  id: string;
  projectId: string;
  state: "inProgress" | "accepted" | "rejected";
  economicEntityDate: string;
  expirationDate?: string;
  creationDate?: string;
  updateDate?: string;
  description: string;
  paxNumber: number;
  revisionNumber?: number;
  logo?: string;
  saleTax: string;
  defaultIncrease?: number;
  defaultMarkup: number;
  isForeignCurrency?: boolean;
  foreignCurrencyType?: string;
  foreignCurrencyExchange?: string;
  feeType?: string;
  feeTax?: string;
  technicalNotes?: string;
  generalConditions?: string;
  extraCharges?: string;
  parentId?: string;
  type: "estimate" | "budget" | "kit" | "free";
  companyId?: string;
  comparisonId?: string;
};

export const useEconomicEntities = (options?: {
  urlParams?:
    | { [key: string]: string }
    | Ref<{
        [key: string]: string;
      }>;
  headers?:
    | {
        [key: string]: string;
      }
    | Ref<{
        [key: string]: string;
      }>;
  enabled?: boolean | Ref<boolean>;
}) => {
  const { data: economicEntities, mutate } = useMySWRV<EconomicEntity[]>(
    `/economicEntities`,
    {
      urlParams: options?.urlParams ?? {},
      headers: options?.headers ?? {},
      enabled: options?.enabled,
    }
  );

  const { t } = useI18n();

  const acceptedEstimateDefaultValues = computed(() => [
    {
      name: "increase",
      type: "percentage",
      amount: acceptedEstimate.value?.defaultIncrease ?? 0,
    },
    {
      name: "markup",
      type: "percentage",
      amount: acceptedEstimate.value?.defaultMarkup ?? 0,
    },
  ]);

  const deleteEconomicEntity = async (id: string) => {
    await fetch(`/economicEntities/${id}`, {
      method: "DELETE",
    });
    await mutate();
  };

  const createEconomicEntity = async (data: Omit<EconomicEntity, "id">) => {
    await fetch("/economicEntities", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    await mutate();
  };

  const editEconomicEntity = async (data: EconomicEntity) => {
    await fetch(`/economicEntities/${data.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    await mutate();
  };

  const updateEconomicEntityState = async (
    economicEntityId: string,
    state: EconomicEntity["state"]
  ) => {
    await fetch(`/updateEconomicEntityState`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ economicEntityId, state }),
    });
    await mutate();
  };

  const inProgressEstimates = computed(
    () =>
      economicEntities.value?.filter(
        (el) => el.type === "estimate" && el.state === "inProgress"
      ) ?? []
  );
  const rejectedEstimates = computed(
    () =>
      economicEntities.value?.filter(
        (el) => el.type === "estimate" && el.state === "rejected"
      ) ?? []
  );
  const acceptedEstimate = computed(() =>
    economicEntities.value?.find(
      (el) => el.type === "estimate" && el.state === "accepted"
    )
  );

  const budget = computed(() =>
    economicEntities.value?.find((el) => el.type === "budget")
  );

  const sponsorEconomicEntities = computed<EconomicEntity[]>(
    () =>
      economicEntities.value?.filter(
        (el) => el.type === "free" || el.type === "kit"
      ) ?? []
  );

  const freeEconomicEntitiesCompanies = computed(
    () =>
      economicEntities.value
        ?.filter((el) => el.type === "free")
        ?.map((el) => el.companyId)
        ?.filter((el, index, self) => self.indexOf(el) === index) as string[]
  );

  const kitEconomicEntitiesCompanies = computed(
    () =>
      economicEntities.value
        ?.filter((el) => el.type === "kit")
        ?.map((el) => el.companyId)
        ?.filter((el, index, self) => self.indexOf(el) === index) as string[]
  );

  const getEconomicEntity = (id: string) =>
    economicEntities.value?.find((el) => el.id === id);

  const getEconomicEntityDescription = (id: string) =>
    getEconomicEntity(id)?.description;

  const getEconomicEntitiesByCompanyId = (
    companyId: string,
    type?: EconomicEntity["type"]
  ) =>
    economicEntities.value?.filter(
      (el) => el.companyId === companyId && (type ? el.type === type : true)
    );

  const kitSponsorEstimates = computed(
    () =>
      economicEntities.value?.filter(
        (el) =>
          el.type === "kit" &&
          economicEntities.value?.find((e) => e.id === el.parentId)?.type ===
            "estimate"
      ) ?? []
  );

  const kitSponsorBudgets = computed(
    () =>
      economicEntities.value?.filter(
        (el) =>
          el.type === "kit" &&
          economicEntities.value?.find((e) => e.id === el.parentId)?.type ===
            "budget"
      ) ?? []
  );

  const acceptedKitSponsorBudgets = computed(
    () => kitSponsorBudgets.value?.filter((el) => el.state === "accepted") ?? []
  );

  const economicEntitiesStates: MenuItemModel[] = [
    {
      text: t("pages.economicEntities.state.inProgress"),
      data: {
        value: "inProgress",
        color: "#0058e9",
        theme: "info",
      },
    },
    {
      text: t("pages.economicEntities.state.rejected"),
      data: {
        value: "rejected",
        color: "#f31700",
        theme: "error",
      },
    },
    {
      text: t("pages.economicEntities.state.accepted"),
      data: {
        value: "accepted",
        color: "#37b400",
        theme: "success",
      },
    },
  ];

  const economicEntitiesFields: Field[] = [
    {
      name: "description",
      label: t(
        "pages.economicEntities.form.fieldset.economicEntityData.fields.description.label"
      ),
      filter: "text",
      operators: Operators.text,
      visible: true,
    },
    {
      name: "economicEntityDate",
      label: t(
        "pages.economicEntities.form.fieldset.economicEntityData.fields.economicEntityDate.label"
      ),
      filter: "date",
      operators: Operators.date,
      visible: true,
      dataType: "date",
    },
    {
      name: "paxNumber",
      label: t(
        "pages.economicEntities.form.fieldset.economicEntityData.fields.paxNumber.label"
      ),
      filter: "text",
      operators: Operators.text,
      visible: true,
    },
    {
      name: "revisionNumber",
      label: t(
        "pages.economicEntities.form.fieldset.economicEntityData.fields.revisionNumber.label"
      ),
      filter: "numeric",
      operators: Operators.numeric,
      visible: true,
    },
    // {
    //   name: "saleTax",
    //   label: t(
    //     "pages.economicEntities.form.fieldset.economicAndFinancialData.fields.saleTax.label"
    //   ),
    //   filter: "text",
    //   operators: Operators.text,
    //   visible: true,
    // },
    {
      name: "feeType",
      label: t(
        "pages.economicEntities.form.fieldset.economicAndFinancialData.fields.feeType.label"
      ),
      filter: "text",
      operators: Operators.text,
      visible: true,
    },
  ];

  return {
    economicEntities,
    inProgressEstimates,
    rejectedEstimates,
    acceptedEstimate,
    budget,
    economicEntitiesStates,
    acceptedEstimateDefaultValues,
    economicEntitiesFields,
    freeEconomicEntitiesCompanies,
    kitEconomicEntitiesCompanies,
    sponsorEconomicEntities,
    kitSponsorBudgets,
    acceptedKitSponsorBudgets,
    kitSponsorEstimates,
    deleteEconomicEntity,
    createEconomicEntity,
    editEconomicEntity,
    updateEconomicEntityState,
    getEconomicEntity,
    getEconomicEntityDescription,
    getEconomicEntitiesByCompanyId,
  };
};
