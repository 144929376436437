import { computed, ref } from "vue";

export const useBreakpoints = () => {
  const breakpoints = {
    desktop: "1024px",
    tablet: "640px",
    mobile: "480px",
  };

  const currentWidth = ref(window.innerWidth);

  const currentBreakpoint = computed(() => {
    if (currentWidth.value < getIntFromPx(breakpoints.mobile)) {
      return "mobile";
    } else if (currentWidth.value < getIntFromPx(breakpoints.tablet)) {
      return "tablet";
    } else {
      return "desktop";
    }
  });

  const isMobile = computed(() => currentBreakpoint.value === "mobile");
  const isTablet = computed(() => currentBreakpoint.value === "tablet");
  const isDesktop = computed(() => currentBreakpoint.value === "desktop");

  const getIntFromPx = (string: string) => parseInt(string.replace("px", ""));

  window.addEventListener("resize", () => {
    currentWidth.value = window.innerWidth;
  });

  return {
    breakpoints,
    currentBreakpoint,
    isMobile,
    isTablet,
    isDesktop,
  };
};
