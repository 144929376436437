/* eslint-disable */

import { EconomicEntity } from "@/app/_resources/useEconomicEntities";
import {
  Comment,
  CompositeCostItem,
  CostItem,
  Header,
  Label,
} from "@/app/_resources/useEconomicEntityItems";

import { baseUrl } from "@/config";
import { handlerGenerator } from "@/mocks/entities";
import { realHandlerGenerator } from "@/mocks/realEntities";

import {
  ApiAgendaSession,
  TimeEntry,
} from "@/app/projects/[projectId]/classes/[classId]/_resources/useAgendas";
import { rest } from "msw";
import { v4 as uuidv4 } from "uuid";


const newEntities = [
  rest.post("/updateEconomicEntityState", async (req, res, ctx) => {
    const { economicEntityId, state } = await req.json();

    const economicEntity = await (
      await fetch(`/economicEntities/${economicEntityId}`)
    ).json();

    const { state: previousState, type: economicEntityType } = economicEntity;

    if (state === "rejected") {
      // Update sponsor estimates states
      const sponsorEstimates = JSON.parse(
        window.localStorage.getItem("economicEntities") || "[]"
      )?.filter(
        (economicEntity: EconomicEntity) =>
          economicEntity.parentId === economicEntityId
      );

      if (sponsorEstimates) {
        for (const sponsorEstimate of sponsorEstimates) {
          await fetch(`/economicEntities/${sponsorEstimate.id}`, {
            method: "PATCH",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ state }),
          });
        }
      }
    }

    // if (state !== "accepted" && previousState === "accepted") {
    //   // await clearBudgetItems();
    //   // await clearSponsorBudgets();
    //   // await clearSponsorBudgetItems();
    // }

    await fetch(`/economicEntities/${economicEntityId}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ state }),
    });

    if (
      state === "accepted" &&
      previousState !== "accepted" &&
      economicEntityType === "estimate"
    ) {
      // Reject other estimates
      const estimates = JSON.parse(
        window.localStorage.getItem("economicEntities") || "[]"
      )?.filter(
        (el: EconomicEntity) =>
          el.projectId === economicEntity.projectId &&
          el.type === "estimate" &&
          el.id !== economicEntityId
      );

      if (estimates) {
        for (const estimate of estimates) {
          await fetch(`/economicEntities/${estimate.id}`, {
            method: "PATCH",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ state: "rejected" }),
          });
        }
      }

      // Create the budget economic entity
      const budgetEconomicEntity = await (
        await fetch("/economicEntities", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...economicEntity,
            state: "inProgress",
            type: "budget",
          }),
        })
      ).json();

      // Create the budget headers
      const headers = JSON.parse(localStorage.getItem("headers") || "[]");

      const estimateHeaders = headers.filter(
        (header: Header) => header.economicEntityId === economicEntityId
      );

      const budgetHeaders: Omit<Header, "id">[] = estimateHeaders?.map(
        (el: Header) => {
          const budgetHeader = {
            ...el,
            economicEntityId: budgetEconomicEntity.id,
            comparisonId: el.id,
          };
          //@ts-ignore
          delete budgetHeader.id;

          return budgetHeader;
        }
      );

      const createBudgetHeader = async (data: Omit<Header, "id">) => {
        const response = await fetch("/headers", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        return response;
      };

      await Promise.all(budgetHeaders?.map(createBudgetHeader));

      const updatedBudgetHeaders: Header[] = JSON.parse(
        localStorage.getItem("headers") || "[]"
      ).filter(
        (header: Header) => header.economicEntityId === budgetEconomicEntity.id
      );

      // Create the budget labels
      const getBudgetHeaderFromEstimateHeader = (estimateHeaderId: string) => {
        const budgetHeader = updatedBudgetHeaders.find(
          (el) => el.comparisonId === estimateHeaderId
        );
        return budgetHeader?.id;
      };

      const labels = JSON.parse(localStorage.getItem("labels") || "[]");

      const estimateLabels = labels.filter(
        (label: Label) => label.economicEntityId === economicEntityId
      );

      const budgetLabels: Omit<Label, "id">[] = estimateLabels?.map(
        (el: Label) => {
          const budgetLabel = {
            ...el,
            economicEntityId: budgetEconomicEntity.id,
            comparisonId: el.id,
            headerId: getBudgetHeaderFromEstimateHeader(el.headerId),
          };
          //@ts-ignore
          delete budgetLabel.id;
          return budgetLabel;
        }
      );

      const createBudgetLabel = async (data: Omit<Label, "id">) => {
        const response = await fetch("/labels", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        return response;
      };

      await Promise.all(budgetLabels?.map(createBudgetLabel));

      // Update labels
      const updatedBudgetLabels: Label[] = JSON.parse(
        localStorage.getItem("labels") || "[]"
      ).filter(
        (label: Label) => label.economicEntityId === budgetEconomicEntity.id
      );

      const getBudgetLabelFromEstimateLabel = (estimateLabelId: string) => {
        const budgetLabel = updatedBudgetLabels.find(
          (el) => el.comparisonId === estimateLabelId
        );
        return budgetLabel?.id;
      };

      // Create the budget cost items
      const costItems = JSON.parse(localStorage.getItem("costItems") || "[]");

      const estimateCostItems = costItems.filter(
        (costItem: CostItem) => costItem.economicEntityId === economicEntityId
      );

      const estimatePackages = estimateCostItems.filter(
        (el: CostItem) => el.isPackage
      );

      const budgetPackages: Omit<CostItem, "id">[] = estimatePackages?.map(
        (el: CostItem) => {
          const budgetPackage = {
            ...el,
            comparisonId: el.id,
            economicEntityId: budgetEconomicEntity.id,
            labelId: el.labelId
              ? getBudgetLabelFromEstimateLabel(el.labelId)
              : undefined,
          };
          //@ts-ignore
          delete budgetPackage.id;
          return budgetPackage;
        }
      );

      const createBudgetCostItem = async (data: Omit<CostItem, "id">) => {
        const response = await fetch("/costItems", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        return response.json();
      };

      await Promise.all(budgetPackages?.map(createBudgetCostItem));

      const estimateCompositeCostItems = JSON.parse(
        localStorage.getItem("compositeCostItems") || "[]"
      ).filter(
        (el: CompositeCostItem) => el.economicEntityId === economicEntityId
      );

      let updatedBudgetCostItems = JSON.parse(
        localStorage.getItem("costItems") || "[]"
      ).filter(
        (el: CostItem) => el.economicEntityId === budgetEconomicEntity.id
      );

      const getBudgetCostItemFromEstimateCostItem = (
        estimateCostItemId: string
      ) => {
        const budgetCostItem = updatedBudgetCostItems.find(
          (el: CostItem) => el.comparisonId === estimateCostItemId
        );
        return budgetCostItem?.id;
      };

      const budgetCompositeCostItems: Omit<CompositeCostItem, "id">[] =
        estimateCompositeCostItems?.map((el: CompositeCostItem) => {
          const budgetCompositeCostItem = {
            ...el,
            packageId: getBudgetCostItemFromEstimateCostItem(el.packageId),
            economicEntityId: budgetEconomicEntity.id,
            comparisonId: el.id,
          };
          //@ts-ignore
          delete budgetCompositeCostItem.id;
          return budgetCompositeCostItem;
        });

      const createBudgetCompositeCostItem = async (
        data: Omit<CompositeCostItem, "id">
      ) => {
        const response = await fetch("/compositeCostItems", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        return response;
      };

      await Promise.all(
        budgetCompositeCostItems?.map(createBudgetCompositeCostItem)
      );

      updatedBudgetCostItems = JSON.parse(
        localStorage.getItem("costItems") || "[]"
      ).filter(
        (el: CostItem) => el.economicEntityId === budgetEconomicEntity.id
      );

      const updatedBudgetCompositeCostItems: CompositeCostItem[] = JSON.parse(
        localStorage.getItem("compositeCostItems") || "[]"
      ).filter(
        (el: CompositeCostItem) =>
          el.economicEntityId === budgetEconomicEntity.id
      );

      const getBudgetCompositeCostItemFromEstimateCompositeCostItem = (
        estimateCompositeCostItemId: string
      ) => {
        const budgetCompositeCostItem = updatedBudgetCompositeCostItems.find(
          (el: CompositeCostItem) =>
            el.comparisonId === estimateCompositeCostItemId
        );
        return budgetCompositeCostItem?.id;
      };

      const estimateBaseCostItems = estimateCostItems.filter(
        (el: CostItem) => !el.isPackage
      );

      const budgetBaseCostItems: Omit<CostItem, "id">[] =
        estimateBaseCostItems?.map((el: CostItem) => {
          const budgetCostItem = {
            ...el,
            comparisonId: el.id,
            labelId: el.labelId
              ? getBudgetLabelFromEstimateLabel(el.labelId)
              : undefined,
            economicEntityId: budgetEconomicEntity.id,
          };
          if (budgetCostItem.packageId) {
            budgetCostItem.packageId = getBudgetCostItemFromEstimateCostItem(
              budgetCostItem.packageId
            );
            if (budgetCostItem.compositeCostItemId) {
              budgetCostItem.compositeCostItemId =
                getBudgetCompositeCostItemFromEstimateCompositeCostItem(
                  budgetCostItem.compositeCostItemId
                );
            }
          }
          //@ts-ignore
          delete budgetCostItem.id;
          return budgetCostItem;
        });

      await Promise.all(budgetBaseCostItems?.map(createBudgetCostItem));

      const estimateComments = JSON.parse(
        localStorage.getItem("comments") || "[]"
      ).filter((el: Comment) => el.economicEntityId === economicEntityId);

      const budgetComments = estimateComments?.map((el: Comment) => {
        const budgetComment = {
          ...el,
          labelId: getBudgetLabelFromEstimateLabel(el.labelId),
          economicEntityId: budgetEconomicEntity.id,
        };
        //@ts-ignore
        delete budgetComment.id;
        return budgetComment;
      });
      const createBudgetComment = async (data: Omit<Comment, "id">) => {
        const response = await fetch("/comments", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        return response;
      };

      await Promise.all(budgetComments?.map(createBudgetComment));

      //Generate Budget Sponsors

      const sponsorEstimates = JSON.parse(
        localStorage.getItem("economicEntities") || "[]"
      ).filter((el: EconomicEntity) => el.parentId === economicEntityId);

      const sponsorBudgets = sponsorEstimates?.map((el: EconomicEntity) => {
        const sponsorBudget = {
          ...el,
          comparisonId: el.id,
          parentId: budgetEconomicEntity.id,
        };
        //@ts-ignore
        delete sponsorBudget.id;
        return sponsorBudget;
      });

      const createSponsorBudget = async (data: Omit<EconomicEntity, "id">) => {
        const response = await fetch("/economicEntities", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        return response.json();
      };

      await Promise.all(sponsorBudgets?.map(createSponsorBudget));

      const updatedSponsorBudgets = JSON.parse(
        localStorage.getItem("economicEntities") || "[]"
      ).filter((el: EconomicEntity) => el.parentId === budgetEconomicEntity.id);

      const getSponsorBudgetFromSponsorEstimate = (
        sponsorEstimateId: string
      ) => {
        const sponsorBudget = updatedSponsorBudgets.find(
          (el: EconomicEntity) => el.comparisonId === sponsorEstimateId
        );
        return sponsorBudget?.id;
      };

      const sponsorEstimateIds = sponsorEstimates.map(
        (el: EconomicEntity) => el.id
      );

      const sponsorBudgetIds = updatedSponsorBudgets.map(
        (el: EconomicEntity) => el.id
      );

      const sponsorEstimateHeaders = JSON.parse(
        localStorage.getItem("headers") || "[]"
      ).filter((el: Header) =>
        sponsorEstimateIds.includes(el.economicEntityId)
      );

      const sponsorBudgetHeaders: Omit<Header, "id">[] =
        sponsorEstimateHeaders.map((el: Header) => {
          const budgeSponsorHeader = {
            ...el,
            economicEntityId: getSponsorBudgetFromSponsorEstimate(
              el.economicEntityId
            ),
            comparisonId: el.id,
          };
          //@ts-ignore
          delete budgeSponsorHeader.id;
          return budgeSponsorHeader;
        });

      await Promise.all(sponsorBudgetHeaders?.map(createBudgetHeader));

      const updatedSponsorBudgetHeaders = JSON.parse(
        localStorage.getItem("headers") || "[]"
      ).filter((el: Header) => sponsorBudgetIds.includes(el.economicEntityId));

      const getSponsorBudgetHeaderFromSponsorEstimateHeader = (
        sponsorEstimateHeaderId: string
      ) => {
        const sponsorBudgetHeader = updatedSponsorBudgetHeaders.find(
          (el: Header) => el.comparisonId === sponsorEstimateHeaderId
        );
        return sponsorBudgetHeader?.id;
      };

      const sponsorEstimateLabels = JSON.parse(
        localStorage.getItem("labels") || "[]"
      ).filter((el: Header) =>
        sponsorEstimateIds.includes(el.economicEntityId)
      );

      const sponsorBudgetLabels: Omit<Label, "id">[] =
        sponsorEstimateLabels?.map((el: Label) => {
          const sponsorBudgetLabel = {
            ...el,
            economicEntityId: getSponsorBudgetFromSponsorEstimate(
              el.economicEntityId
            ),
            comparisonId: el.id,
            headerId: getSponsorBudgetHeaderFromSponsorEstimateHeader(
              el.headerId
            ),
          };
          //@ts-ignore
          delete sponsorBudgetLabel.id;
          return sponsorBudgetLabel;
        });

      await Promise.all(sponsorBudgetLabels?.map(createBudgetLabel));

      const updatedSponsorBudgetLabels = JSON.parse(
        localStorage.getItem("labels") || "[]"
      ).filter((el: Label) => sponsorBudgetIds.includes(el.economicEntityId));

      const getSponsorBudgetLabelFromSponsorEstimateLabel = (
        sponsorEstimateLabelId: string
      ) => {
        const sponsorBudgetLabel = updatedSponsorBudgetLabels.find(
          (el: Label) => el.comparisonId === sponsorEstimateLabelId
        );
        return sponsorBudgetLabel?.id;
      };

      const sponsorEstimateCostItems = JSON.parse(
        localStorage.getItem("costItems") || "[]"
      ).filter((el: CostItem) =>
        sponsorEstimateIds.includes(el.economicEntityId)
      );

      const sponsorEstimatePackages = sponsorEstimateCostItems.filter(
        (el: CostItem) => el.isPackage
      );

      const sponsorBudgetPackages: Omit<CostItem, "id">[] =
        sponsorEstimatePackages?.map((el: CostItem) => {
          const sponsorBudgetPackage = {
            ...el,
            comparisonId: el.id,
            economicEntityId: getSponsorBudgetFromSponsorEstimate(
              el.economicEntityId
            ),
            labelId: el.labelId
              ? getSponsorBudgetLabelFromSponsorEstimateLabel(el.labelId)
              : undefined,
          };
          //@ts-ignore
          delete sponsorBudgetPackage.id;
          return sponsorBudgetPackage;
        });

      await Promise.all(sponsorBudgetPackages?.map(createBudgetCostItem));

      const sponsorEstimateCompositeCostItems = JSON.parse(
        localStorage.getItem("compositeCostItems") || "[]"
      ).filter((el: CompositeCostItem) =>
        sponsorEstimateIds.includes(el.economicEntityId)
      );

      let updatedSponsorBudgetCostItems = JSON.parse(
        localStorage.getItem("costItems") || "[]"
      ).filter((el: CostItem) =>
        sponsorBudgetIds.includes(el.economicEntityId)
      );

      const getSponsorBudgetCostItemFromSponsorEstimateCostItem = (
        sponsorEstimateCostItemId: string
      ) => {
        const sponsorBudgetCostItem = updatedSponsorBudgetCostItems.find(
          (el: CostItem) => el.comparisonId === sponsorEstimateCostItemId
        );
        return sponsorBudgetCostItem?.id;
      };

      const sponsorBudgetCompositeCostItems: Omit<CompositeCostItem, "id">[] =
        sponsorEstimateCompositeCostItems?.map((el: CompositeCostItem) => {
          const sponsorBudgetCompositeCostItem = {
            ...el,
            packageId: getSponsorBudgetCostItemFromSponsorEstimateCostItem(
              el.packageId
            ),
            comparisonId: el.id,
            economicEntityId: getSponsorBudgetFromSponsorEstimate(
              el.economicEntityId
            ),
          };
          //@ts-ignore
          delete sponsorBudgetCompositeCostItem.id;

          return sponsorBudgetCompositeCostItem;
        });

      await Promise.all(
        sponsorBudgetCompositeCostItems?.map(createBudgetCompositeCostItem)
      );

      updatedSponsorBudgetCostItems = JSON.parse(
        localStorage.getItem("costItems") || "[]"
      ).filter((el: CostItem) =>
        sponsorBudgetIds.includes(el.economicEntityId)
      );

      const updatedSponsorBudgetCompositeCostItems = JSON.parse(
        localStorage.getItem("compositeCostItems") || "[]"
      ).filter((el: CompositeCostItem) =>
        sponsorBudgetIds.includes(el.economicEntityId)
      );

      const getSponsorBudgetCompositeCostItemFromSponsorEstimateCompositeCostItem =
        (sponsorEstimateCompositeCostItemId: string) => {
          const sponsorBudgetCompositeCostItem =
            updatedSponsorBudgetCompositeCostItems.find(
              (el: CompositeCostItem) =>
                el.comparisonId === sponsorEstimateCompositeCostItemId
            );
          return sponsorBudgetCompositeCostItem?.id;
        };

      const sponsorEstimateBaseCostItems = sponsorEstimateCostItems?.filter(
        (el: CostItem) => !el.isPackage
      );

      const sponsorBudgetBaseCostItems: Omit<CostItem, "id">[] =
        sponsorEstimateBaseCostItems?.map((el: CostItem) => {
          const sponsorBudgetCostItem = {
            ...el,
            labelId: el.labelId
              ? getSponsorBudgetLabelFromSponsorEstimateLabel(el.labelId)
              : undefined,
            comparisonId: el.id,
            economicEntityId: getSponsorBudgetFromSponsorEstimate(
              el.economicEntityId
            ),
          };
          if (sponsorBudgetCostItem.packageId) {
            sponsorBudgetCostItem.packageId =
              getSponsorBudgetCostItemFromSponsorEstimateCostItem(
                sponsorBudgetCostItem.packageId
              );
            if (sponsorBudgetCostItem.compositeCostItemId) {
              sponsorBudgetCostItem.compositeCostItemId =
                getSponsorBudgetCompositeCostItemFromSponsorEstimateCompositeCostItem(
                  sponsorBudgetCostItem.compositeCostItemId
                );
            }
          }
          //@ts-ignore
          delete sponsorBudgetCostItem.id;
          return sponsorBudgetCostItem;
        });

      await Promise.all(sponsorBudgetBaseCostItems?.map(createBudgetCostItem));

      const sponsorEstimateComments = JSON.parse(
        localStorage.getItem("comments") || "[]"
      ).filter((el: Comment) =>
        sponsorEstimateIds.includes(el.economicEntityId)
      );

      const sponsorBudgetComments = sponsorEstimateComments?.map(
        (el: Comment) => {
          const sponsorBudgetComment = {
            ...el,
            labelId: getSponsorBudgetLabelFromSponsorEstimateLabel(el.labelId),
            economicEntityId: getSponsorBudgetFromSponsorEstimate(
              el.economicEntityId
            ),
          };
          //@ts-ignore
          delete sponsorBudgetComment.id;
          return sponsorBudgetComment;
        }
      );

      await Promise.all(sponsorBudgetComments?.map(createBudgetComment));
    }
  }),
  ...handlerGenerator("projects"),
  ...realHandlerGenerator(
    {
      storage: "sponsorBusinesses",
      fetched: "sponsorBusinesses",
    },
    false
  ),
  ...realHandlerGenerator(
    {
      storage: "classes",
      fetched: "classes",
    },
    false
  ),

  rest.post(`${baseUrl}/agendaSessions`, async (req, res, ctx) => {
    const item = await req.json();
    const items: ApiAgendaSession[] = JSON.parse(
      window.localStorage.getItem("agendaSessions") || "[]"
    );

    const isOverlapping = (a: TimeEntry, b: TimeEntry) =>
      new Date(a.startTime) < new Date(b.endTime) &&
      new Date(a.endTime) > new Date(b.startTime);

    // Check if time entries are overlapping within the same session
    for (let i = 0; i < item.timeEntries.length; i++) {
      for (let j = i + 1; j < item.timeEntries.length; j++) {
        if (isOverlapping(item.timeEntries[i], item.timeEntries[j])) {
          return res(
            ctx.status(400),
            ctx.json({
              message: "Time entries are overlapping within the same session",
            })
          );
        }
      }
    }

    // Check if time entries are overlapping with other agenda sessions
    for (const otherItem of items) {
      if (otherItem.keyId === item.keyId) continue;
      for (const timeEntry1 of item.timeEntries) {
        for (const timeEntry2 of otherItem.timeEntries) {
          if (isOverlapping(timeEntry1, timeEntry2)) {
            return res(
              ctx.status(400),
              ctx.json({
                message: "Time entries are overlapping with another session",
              })
            );
          }
        }
      }
    }

    const id = item.keyId || item.id;
    if (id) {
      const targetIndex = items.findIndex(
        (el: any) => el.keyId === id || el.id === id
      );
      items.splice(targetIndex, 1, item);
    } else {
      item.keyId = uuidv4();
      item.id = item.keyId;
      items.push(item);
    }
    window.localStorage.setItem("agendaSessions", JSON.stringify(items));
    return res(ctx.status(200), ctx.json(item));
  }),
  ...realHandlerGenerator(
    {
      storage: "agendaSessions",
      fetched: "agendaSessions",
    },
    false
  ),

  ...realHandlerGenerator(
    {
      storage: "providers",
      fetched: "providers",
    },
    false
  ),

  ...handlerGenerator("costItems"),
  ...handlerGenerator("sponsorKits"),
  ...handlerGenerator("orders"),
  ...handlerGenerator("compositeCostItems"),
  ...handlerGenerator("comments"),
  ...handlerGenerator("headers"),
  ...handlerGenerator("labels"),
  ...handlerGenerator("sponsorServices"),
  ...handlerGenerator("economicEntities"),
];

export const handlers = [...newEntities];
