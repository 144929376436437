import { I18nOptions } from "vue-i18n";

export const itDateTime: I18nOptions["datetimeFormats"] = {
  it: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    time: {
      hour: "numeric",
      minute: "numeric",
    },
  },
};
