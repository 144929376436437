export const iconMap = {
  account: {
    avatar: "person",
    logout: "logout",
  },
  actions: {
    add: "add",
    cancel: "close",
    close: "close",
    confirm: "check",
    continue: "arrow_forward",
    create: "add",
    delete: "delete",
    detail: "wysiwyg",
    download: "download",
    dragHandle: "drag_indicator",
    save: "save",
    search: "search",
    edit: "edit",
    upload: "upload",
    move: "forward",
    lock: "lock",
    unlock: "lock_open",
    reset: "restart_alt",
    calculate: "calculate",
    assignSupplier: "person_add",
    createOrder: "shopping_cart",
    changeSupplier: "manage_accounts",
  },
  analytics: {
    increase: "arrow_upward",
    decrease: "arrow_downward",
  },
  collection: {
    columns: "view_column",
    dropdown: "play_arrow",
    filter: "filter_alt",
    emptyState: "create_new_folder",
    emptyStateNoCreate: "folder",
    error: "error",
  },
  context: {
    anagraphics: "data_table",
    backoffice: "database",
    economicManagement: "account_balance_wallet",
    classes: "book",
    classesHealthcare: "medical_services",
    classesGeneric: "school",
    classesEcm: "article",
    classesCme: "article",
    projectMonosponsor: "person_play",
    projectMultisponsor: "groups_3",
    dashboard: "dashboard",
    estimates: "receipt",
    home: "home",
    notifications: "notifications",
    projects: "workspaces",
    sponsorBusiness: "apartment",
    sponsorKits: "package",
    registrationServices: "handshake",
    registrationPackages: "deployed_code_alert",
    sponsorServices: "bookmark",
    orders: "order_approve",
    registrationCategories: "speaker_group",
    portalUsers: "group",
    portalRoles: "badge",
    projectRoles: "manage_accounts",
    spaces: "activity_zone",
    staff: "account_circle",
  },
  costItems: {
    comment: "comment",
    package: "package",
    service: "bookmark",
  },
  generic: "folder",
  navigation: {
    back: "arrow_back",
    prev: "arrow_back",
    menu: "menu",
    next: "arrow_forward",
  },
  placeholder: "image",
};
