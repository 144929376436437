import { filterBy } from "@progress/kendo-data-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import useSWRV, { type IConfig } from "swrv";
import { computed, ref, watchEffect, type Ref } from "vue";
import { useI18n } from "vue-i18n";

import { baseUrl } from "@/config";
import { useNotifications } from "@/ui/useNotifications";

import { useAuth } from "./useAuth";

export type PaginatedResponse<T> = {
  elements: T[];
  paging: {
    totalRecords: number;
    pageNumber: number;
    pageSize: number;
  };
};

export const useMyFetch = () => {
  const { refreshToken } = useAuth();

  return async (
    path: string,
    options: {
      method?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
      urlParams?: {
        [key: string]: string;
      };
      headers?: {
        [key: string]: string;
      };
      body?: any;
    }
  ) => {
    const getHeaders = () => {
      const defaultHeaders: {
        [key: string]: string;
      } = {
        authorizationToken: `Bearer ${localStorage.getItem("jwt")}` || "",
        accept: "application/json",
      };

      if (options.method !== "DELETE") {
        defaultHeaders["Content-Type"] = "application/json";
      }
      return {
        ...defaultHeaders,
        ...options.headers,
      };
    };

    const cleanObject = (obj: any) => {
      for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined) {
          delete obj[key];
        } else if (Array.isArray(obj[key])) {
          obj[key] = obj[key].map((item: any) => {
            if (typeof item === "object") {
              return cleanObject(item);
            } else {
              return item;
            }
          });
        } else if (typeof obj[key] === "object") {
          cleanObject(obj[key]);
        }
      }
      return obj;
    };

    const cleanBody = (body: any) => {
      if (body && options.method === "POST") {
        const bodyObj = JSON.parse(body);
        return JSON.stringify(cleanObject(bodyObj));
      }
      return body;
    };

    const request = async () => {
      return await fetch(
        path + "?" + new URLSearchParams(options.urlParams ?? {}),
        {
          method: options.method,
          headers: getHeaders(),
          body: options.body ? cleanBody(JSON.stringify(options.body)) : null,
        }
      );
    };

    let response = await request();

    if (response.status === 401 || response.status === 403) {
      //@ts-ignore
      const { status } = await refreshToken();
      if (status === 200) {
        response = await request();
      } else {
        throw new Error("Unauthorized");
      }
    }

    /* if (!response.ok) {
                                                 throw new Error(response.statusText);
                                               }*/

    return response;
  };
};

export const useMySWRV = <T = any>(
  path: string | Ref<string>,
  options: {
    key?: Ref;
    method?: "GET" | "POST" | Ref<"GET" | "POST">;
    urlParams?:
      | {
          [key: string]: string;
        }
      | Ref<{
          [key: string]: string;
        }>;
    headers?:
      | {
          [key: string]: string;
        }
      | Ref<{
          [key: string]: string;
        }>;
    body?: JSON | Ref<JSON>;
    SWRVOptions?: IConfig;
    enabled?: boolean | Ref<boolean>;
  }
) => {
  const myFetch = useMyFetch();

  const {
    key,
    method = "GET",
    urlParams = {},
    headers = {},
    body,
    SWRVOptions,
    enabled = true,
  } = options;

  const reactivePath = ref(path);
  const reactiveMethod = ref(method);
  const reactiveUrlParams = ref(urlParams);
  const reactiveBody = ref(body);
  const reactiveHeaders = ref(headers);
  const reactiveEnabled = ref(enabled);

  const SWRVKey = computed(() => {
    if (key) {
      return key.value;
    } else {
      return JSON.stringify({
        method: reactiveMethod.value,
        path: reactivePath.value,
        urlParams: reactiveUrlParams.value,
        body: reactiveBody.value,
        headers: reactiveHeaders.value,
      });
    }
  });

  const apiCall = async () => {
    if (reactiveEnabled.value) {
      return (
        await myFetch(reactivePath.value, {
          method: reactiveMethod.value,
          urlParams: reactiveUrlParams.value,
          headers: reactiveHeaders.value,
          body: reactiveBody.value,
        })
      ).json();
    }
  };

  const { data, error, isValidating, mutate } = useSWRV<T>(SWRVKey, apiCall, {
    ...SWRVOptions,
    revalidateOnFocus: location.href.includes("localhost")
      ? false
      : SWRVOptions?.revalidateOnFocus,
  });

  return {
    data,
    mutate,
    isValidating,
    error,
  };
};

type ApiMutationOptions<T> = {
  hideSuccessNotification?: boolean;
  hideErrorNotification?: boolean;
  onSuccess?: (data: T) => void;
  onError?: () => void;
};

type ApiFindOptions<T> = {
  searchParams?: Ref<Record<string, any>>;
  queryFilters?: Ref<any>;
  enabled?: Ref<boolean>;
  refetchOnWindowFocus?: Ref<boolean>;
  onSuccess?: (data: T[]) => void;
  onError?: () => void;
};

type ApiGetOptions<T> = {
  keyId?: Ref<string | number>;
  enabled?: Ref<boolean>;
  refetchOnWindowFocus?: Ref<boolean>;
  onSuccess?: (data: T) => void;
  onError?: () => void;
};

export type ApiOptions<T> = {
  find?: ApiFindOptions<T>;
  findPaginated?: ApiFindOptions<T>;
  get?: ApiGetOptions<T>;
  create?: ApiMutationOptions<T>;
  update?: ApiMutationOptions<T>;
  remove?: ApiMutationOptions<T>;
};

type BaseEntity<T> = { keyId: number | string } & T;

type CrudOptions<T, J> = {
  entityKey: string;
  parentEntityKey?: string;
  apiToEntity: (data: J) => BaseEntity<T>;
  entityToApi: (data: T) => J;
  apiOptions: ApiOptions<T>;
  mock?: {
    enabled?: boolean;
    defaultData?: BaseEntity<J>[];
  };
};

export const useCrud = <T, J>(options: CrudOptions<T, J>) => {
  const { entityKey, parentEntityKey } = options;
  const { apiOptions, apiToEntity, entityToApi } = options;

  const queryClient = useQueryClient();
  const myFetch = useMyFetch();
  const { showNotification } = useNotifications();
  const { t } = useI18n();

  const storage = {
    get(): any[] {
      return (
        JSON.parse(localStorage.getItem(entityKey) ?? "null") ??
        options.mock?.defaultData ??
        []
      );
    },
    set(val: any) {
      localStorage.setItem(entityKey, JSON.stringify(val));
    },
  };

  const isMocked = options.mock?.enabled ?? false;

  const keyId = computed(() => apiOptions?.get?.keyId?.value ?? "");
  const findParams = computed(
    () => apiOptions?.find?.searchParams?.value ?? {}
  );
  const findPaginatedQueryFilters = computed(
    () => apiOptions?.findPaginated?.queryFilters?.value ?? {}
  );
  const findQueryFilters = computed(
    () => apiOptions?.find?.queryFilters?.value ?? {}
  );
  const findPaginatedParams = computed(
    () => apiOptions?.findPaginated?.searchParams?.value ?? {}
  );

  const get = useQuery({
    queryKey: [entityKey, keyId],
    queryFn: async (context) => {
      if (isMocked) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        const data = storage.get().find((item) => item.keyId === keyId.value)!;
        return apiToEntity(data);
      }

      const response = await myFetch(
        `${baseUrl}/${entityKey}/${encodeURIComponent(keyId.value)}`,
        {}
      );

      const data = await response.json();

      if (!response.ok) {
        return Promise.reject({ response, data });
      }

      const parsedData = apiToEntity(data);

      return parsedData;
    },
    enabled: apiOptions?.get?.enabled ?? false,
    refetchOnWindowFocus: apiOptions?.get?.refetchOnWindowFocus ?? true,
  });

  watchEffect(() => {
    if (get.isError.value) {
      showNotification({
        title: (get.error.value as any).data.message,
        icon: true,
        theme: "error",
        closable: true,
      });
      apiOptions?.get?.onError?.();
    }
  });

  watchEffect(() => {
    if (get.isSuccess.value) {
      apiOptions?.get?.onSuccess?.(get.data.value);
    }
  });

  const find = useQuery({
    queryKey: [entityKey, findParams, findQueryFilters],
    queryFn: async (context) => {
      if (isMocked) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        const data: any[] = storage.get();
        const filteredData = filterBy(data ?? [], {
          operator: findQueryFilters.value.operator ?? "and",
          filters: findQueryFilters.value.filters ?? [],
        });
        return filteredData.map((item) => apiToEntity(item));
      }

      const response = await myFetch(`${baseUrl}/${entityKey}`, {
        urlParams: findParams.value,
        headers: {
          "X-Header-Filter": window.btoa(
            JSON.stringify(findQueryFilters.value)
          ),
        },
      });

      const data: any[] = await response.json();

      if (!response.ok) {
        return Promise.reject({ response, data });
      }

      return (data ?? []).map((item) => apiToEntity(item));
    },
    keepPreviousData: true,
    enabled: apiOptions?.find?.enabled ?? false,
    refetchOnWindowFocus: apiOptions?.find?.refetchOnWindowFocus ?? true,
  });

  watchEffect(() => {
    if (find.isError.value) {
      showNotification({
        title: (find.error.value as any).data.message,
        icon: true,
        theme: "error",
        closable: true,
      });
      apiOptions?.find?.onError?.();
    }
  });

  watchEffect(() => {
    if (find.isSuccess.value) {
      apiOptions?.find?.onSuccess?.(find.data.value!);
    }
  });

  const findPaginated = useQuery({
    queryKey: [entityKey, findPaginatedParams, findPaginatedQueryFilters],
    queryFn: async (context) => {
      if (isMocked) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        const pageSize = Number(findPaginatedParams.value?.pageSize ?? 10);
        const pageNumber = Number(findPaginatedParams.value?.pageNumber ?? 1);
        const data: any[] = storage.get();
        const filteredData = filterBy(data ?? [], {
          operator: findPaginatedQueryFilters.value.operator ?? "and",
          filters: findPaginatedQueryFilters.value.filters ?? [],
        });
        const pages =
          pageSize > 0
            ? Array(Math.ceil(filteredData.length / pageSize))
                .fill(null)
                .map((_, index) => index * pageSize)
                .map((begin) => filteredData.slice(begin, begin + pageSize))
            : [filteredData];

        const page = (pageSize > 0 ? pages[pageNumber - 1] : pages[0]) ?? [];
        const elements = page.map((item: any) => apiToEntity(item));

        return {
          elements,
          paging: {
            totalRecords: data.length,
            pageNumber,
            pageSize,
          },
        };
      }

      const response = await myFetch(`${baseUrl}/${entityKey}`, {
        urlParams: findPaginatedParams.value,
        headers: {
          "X-Header-Filter": window.btoa(
            JSON.stringify(findPaginatedQueryFilters.value)
          ),
        },
      });

      const data: PaginatedResponse<J> = await response.json();

      if (!response.ok) {
        return Promise.reject({ response, data });
      }

      return {
        ...data,
        elements: (data?.elements ?? []).map((item) => apiToEntity(item)),
      };
    },
    keepPreviousData: true,
    enabled: apiOptions?.findPaginated?.enabled ?? false,
    refetchOnWindowFocus:
      apiOptions?.findPaginated?.refetchOnWindowFocus ?? true,
  });

  const create = useMutation({
    mutationFn: async (variables: Omit<T, "keyId">) => {
      if (isMocked) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        const data: any = entityToApi({
          ...(variables as any),
          keyId: Date.now(),
        });
        storage.set([...storage.get(), data]);
        return apiToEntity(data);
      }

      const response = await myFetch(`${baseUrl}/${entityKey}`, {
        method: "POST",
        body: entityToApi(variables as any),
      });

      const data = await response.json();

      if (!response.ok) {
        return Promise.reject({ response, data });
      }

      return apiToEntity(data);
    },
    onSuccess(data, variables, context) {
      if (!apiOptions.create?.hideSuccessNotification) {
        showNotification({
          title: t("notifications.create"),
          icon: true,
          theme: "success",
          closable: true,
        });
      }

      queryClient.invalidateQueries({ queryKey: [entityKey] });

      if (parentEntityKey) {
        queryClient.invalidateQueries({ queryKey: [parentEntityKey] });
      }

      apiOptions?.create?.onSuccess?.(data);
    },
    onError(error: any, variables, context) {
      if (!apiOptions.create?.hideErrorNotification) {
        showNotification({
          title: error.data.message,
          icon: true,
          theme: "error",
          closable: true,
        });
      }

      apiOptions?.create?.onError?.();
    },
  });

  const update = useMutation({
    mutationFn: async (variables: T) => {
      if (isMocked) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        const storageData = storage.get();
        const index = storageData.findIndex(
          (item) => item.keyId === (variables as any).keyId
        )!;
        const data: any = entityToApi(variables as any);
        storageData.splice(index, 1, data);
        storage.set(storageData);
        return apiToEntity(data);
      }

      const response = await myFetch(`${baseUrl}/${entityKey}`, {
        method: "POST",
        body: entityToApi(variables),
      });

      const data = await response.json();

      if (!response.ok) {
        return Promise.reject({ response, data });
      }

      return apiToEntity(data);
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({ queryKey: [entityKey] });
      queryClient.invalidateQueries({
        queryKey: [entityKey, data.keyId],
      });

      if (parentEntityKey) {
        queryClient.invalidateQueries({ queryKey: [parentEntityKey] });
      }

      apiOptions?.update?.onSuccess?.(data);
    },
    onError(error: any, variables, context) {
      if (!apiOptions.update?.hideErrorNotification) {
        showNotification({
          title: error.data.message,
          icon: true,
          theme: "error",
          closable: true,
        });
      }

      apiOptions?.update?.onError?.();
    },
  });

  const remove = useMutation({
    mutationFn: async (context: number | string) => {
      if (isMocked) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        const storageData = storage.get();
        const index = storageData.findIndex((item) => item.keyId === context)!;
        const [data] = storageData.splice(index, 1);
        storage.set(storageData);
        return apiToEntity(data);
      }

      const response = await myFetch(
        `${baseUrl}/${entityKey}/${encodeURIComponent(context)}`,
        {
          method: "DELETE",
        }
      );

      const data = await response.json();

      if (!response.ok) {
        return Promise.reject({ response, data });
      }

      return apiToEntity(data);
    },

    onSuccess(data, variables, context) {
      if (!apiOptions.remove?.hideSuccessNotification) {
        showNotification({
          title: t("notifications.delete"),
          icon: true,
          theme: "info",
          closable: true,
        });
      }

      queryClient.invalidateQueries({ queryKey: [entityKey] });

      if (parentEntityKey) {
        queryClient.invalidateQueries({ queryKey: [parentEntityKey] });
      }

      apiOptions?.remove?.onSuccess?.(data);
    },
    onError(error: any, variables, context) {
      if (!apiOptions.remove?.hideErrorNotification) {
        showNotification({
          title: error.data.message,
          icon: true,
          theme: "error",
          closable: true,
        });
      }

      apiOptions?.remove?.onError?.();
    },
  });

  return {
    get,
    find,
    findPaginated,
    create,
    update,
    remove,
  };
};
