import { addMethod, string, TestFunction } from "yup";

export const initMethods = () => {
  addMethod(string, "unique", function (errorMessage: string, array: string[]) {
    return this.test("unique", function (value: string) {
      if (!array.includes(value)) {
        return true;
      } else {
        return this.createError({ message: errorMessage });
      }
    } as TestFunction);
  });
};
